<template>

      <label class="switch">
        <input type="checkbox" :checked="value" @input="onInput"/>
        <span class="slider round"></span>
      </label>
</template>


<script>


    export default{

        name:"uiSwitch",
        props:{

            value:{
            
            },
            outputType:{
              required:false,
              default:'boolean'

            }

        },

        data(){

            return {


            }

        },

        methods:{

          onInput(event){


            this.$emit('input', this.outputType=='boolean'?event:(event.target.checked?1:0));
          }
        },

        mounted(){



        }



    }

</script>


<style lang="scss" scoped>

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
  margin-bottom: 0;
  overflow: hidden;
}

.switch input {display:none;}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c3bdbd;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #ca6da4;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ca6da4;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 16px;
}

.slider.round:before {
  border-radius: 50%;}




</style>