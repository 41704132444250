<template>


<section class="document-section">
    <div class="card">
        <div class="card-body">
            <div v-html="model.content"></div>
        </div>
    </div>

</section>



</template>

<script>

    import api from '@/util/api.js';

    export default{
        routeName:'documents',
        name:'viewDocument',
        components:{


        },

        props:{
            id:{
                required:true
            }
        },
        data(){

            return {

                model:{}
            }

        },

        mounted(){


            api.get(this.me('/detail?id='+this.id)).then(r=>{
                this.model=r.data;
            }).catch();
            


        }



    }

</script>


<style lang="scss" scoped>



</style>