<template>

<div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th><input type="checkbox"></th>
                    <slot name="headers"></slot>
                  </tr>
                </thead>
                <tbody>

                  <tr v-if="listing">
                    
                    <td colspan="10">
                      <b-skeleton-table
                              
                              :rows="5"
                              :columns="1"
                              :hide-header="true"
                              :table-props="{ bordered: false, striped: false,'table-class':'skeleton-table'}"
                      ></b-skeleton-table>
                    </td>
                  </tr>

                  <tr v-for="(item, index) in items" :key="index">
                    <td><input type="checkbox"></td>
                    <slot name="row" v-bind="item"></slot>

                      

                      <td>

                      <slot name="start" v-bind="{item,index}"></slot>
                      <slot name="edit" v-if="updatable" v-bind="{item,index}">
                        <router-link class="action-link" :to="'/'+routeName+'/update/'+item.id"><i class="mdi mdi-square-edit-outline"></i></router-link>                        
                      </slot>
                      <slot name="center"  v-bind="{item,index}"></slot>
                      <slot name="delete" v-if="deletable" v-bind="{item,index}">
                        <a class="action-link" @click="deleteMe(item.id,index)"><i class="mdi mdi-delete-outline"></i></a>
                      </slot>
                      <slot name="end"  v-bind="{item,index}"></slot>
                    </td>


                  </tr>
                </tbody>
              </table>
            </div>

</template>


<script>

    import api from '@/util/api.js';


    export default{

        name:"tableGrid",
        components:{

        },
        props:{
          routeName:{
            
          },
          
          data:{

          },

          deletable:{
            type:Boolean,
            default:true,
            required:false
          },
          
          updatable:{
            type:Boolean,
            default:true,
            required:false
          }
          

        },
        data(){

            return {
                items:[],
                listing:false
            }
        },
        methods:{

          getItems() {
            this.listing=true;
            api.get('/'+this.routeName).then(response => {
              this.items = response.data;

              
            }).catch(error => {
              console.log(error);
            }).finally(()=>{


                this.listing=false;

            });
          },

          deleteMe(id,index){
            if(confirm('¿Desea eliminar el elemento?')) {

                    api.delete('/'+this.routeName+"/"+id).then(()=>{

                      this.items.splice(index,1);
                      this.$forceUpdate();

                    }).catch(error=>{

                      console.log(error);
                      
                    });

              }

            }

        },

        mounted(){

            if(this.data){

              this.items=this.data;
            }else{

              this.getItems();
            }
          }

        

    }

</script>

<style lang="scss" scoped>

.skeleton-table{

  td{
    
    div{
      width:100% !important
    }
  }

}

</style>