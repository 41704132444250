<template>

    <section>

            <l-map

              ref="lmap"
              :key="key_map"
              :min-zoom="map.minZoom"
              :crs="map.crs"
              style="height: 550px; width:100%"
              :center="map.center"
              :bounds="map.bounds"
              :maxBounds="map.maxBounds"
              :maxBoundsViscosity="1000"
              :easeLinearity="0"
              :inertia="false"
              :options="map.options"
              class="map-center"
              >

              <l-control-zoom :position="'bottomright'" />

              <!--
              <l-control position="topleft">
                
              </l-control>-->
            

              <l-control position="topleft" class="l-control-center">

                

                <b-input-group class="group-center">
                  <template #prepend class="controls-prepend">
                    <span class="dev-thumb" v-b-tooltip :title="development.name"><img :src="$thumb(development.featured_image)"/></span>
                    <b-dropdown :text="selectedStage" v-b-tooltip title="Seleccionar fase" variant="success" class="c-dropdown">
                      <template #button-content>
                        <i class="mdi mdi-map-search-outline"></i>
                        <span>{{selectedPhase}}</span>
                      </template>

                      <b-dropdown-item v-for="(phase,index) in phases" :key="index" @click="loadPhase(index)">{{ phase.name }}</b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown :text="selectedStage" v-b-tooltip title="Seleccionar etapa" variant="success" class="c-dropdown">
                      <template #button-content>
                        <i class="mdi mdi-layers-outline"></i>
                        <span>{{selectedStage}}</span>
                      </template>
                      <b-dropdown-item v-for="(stage,index) in stages" :key="index" @click="loadStage(index)"> {{ stage.name }}</b-dropdown-item>
                    </b-dropdown>
                  </template>
                  <template #append>
                    <b-form-input class="srch-input" v-model="searchKey" autocomplete="off" @input="onSearchInput" placeholder="Buscar una propiedad por código"></b-form-input>
                    <b-button class="srch-btn" variant="success"><i class="mdi mdi-home-search-outline mdi-24px"></i></b-button>
                  </template>
                  

                </b-input-group>

              </l-control>

              <l-control position="bottomleft">
                <span class="map-tip-c"><i class="mdi mdi-map-marker-plus-outline"></i> Haz clic sobre un marcador para cotizar la unidad</span>
              
              </l-control>



              <l-image-overlay
                :url="map.url"
                :bounds="map.bounds"
                
              />
              <l-marker
                v-for="(marker,idx) in getMarkers"
                :key="idx"
                :lat-lng="{lat:marker.position_x,lng:marker.position_y}"
                :icon="marker.icon"
                :draggable="marker.draggable"
                ref="markers"
                @click="onMarkerClick(idx)"
                
              >
                <!--<l-popup :options="{minWidth:250,offset:map.offset,keepInView:true}">

                
                </l-popup>-->


              <l-tooltip :options="{permanent:searchKey!=''?true:false}" :key="marker.counter"><strong>Clave:</strong> {{ marker.code }} <br><strong>Modelo:</strong> {{marker.design.name}} <br><strong>Precio:</strong> ${{ marker.design.price }}</l-tooltip>

              </l-marker>



              <l-polyline :lat-lngs="map.travel" />
              </l-map>

 

  
              <b-modal ref="quotation_dialog" size="lg" title="Cotizar unidad" :hide-footer="true">
                  <formQuotation @saved="onQuotationSaved" :property_id="property_id" :property="prop" :sale_price="sale_price" :development_id="development_id"/>
              </b-modal>



</section>

</template>


<script>
import api from '@/util/api.js';

import L from 'leaflet';
import { CRS } from "leaflet";
import { latLngBounds } from "leaflet";

import formQuotation from '@/pages/quotations/_form';
//import submitButton from '@/components/ui-feature/submit-button';



import {  LMap, LImageOverlay, LMarker, /*LPopup,*/LTooltip, LPolyline,LControl,LControlZoom} from "vue2-leaflet";

export default{

  routeName:'quotations',
  name:"quotationMap",
  components:{
        LMap,
        LImageOverlay,
        LMarker,
    //    LPopup,
        LPolyline,
        LTooltip,
        LControl,
        LControlZoom,
        formQuotation,
      //  submitButton
  },

  props:{

    development_id:{
      required:true
    }
    
  },

  data(){

    return {

        property_id:0,
        sale_price:0,        
        loading:false,
        searchKey:'',
        selectedPhase:'',
        toggledPhasesPanel:0,
        currentPhase:'',
        map_padding:350,
        prop:{design:{name:''}},
        dev_image:'',
        dev_title:'',
        properties:[],
        stages:[],
        phases:[],
        selectedStage:'',
        development:{id:0},
        key_map:0,
        map:{

              baseUrl:'',
                url: '',
                offset:L.point(0, -30),
                //autoPanPadding:L.point(200,200),
                bounds:[],
                maxBounds:[],
                center: [],
                minZoom: 0,
                crs: CRS.Simple,
                markers: [],
                options:{zoomControl:false},
                fixedtips:false
            }

    }

  },
  computed:{






    getMarkers(){
  
        const key=this.searchKey.toLowerCase();



        return this.map.markers.filter(marker =>{
          
          marker.counter++;
          const it_includes=marker.code.toLowerCase().includes(key);          
          return it_includes;            
          }
        );

    },

    hasPhases(){

      return this.phases.length>0;
    },  
    isModelReady(){

      return this.development.id?true:false;

    },


    hasProperties(){

      return this.properties.length?true:false;

    }


    },

    methods:{



      onQuotationSaved(data){

        this.$emit('savedQuote',data);
      },

      onLayerClick(index){

        alert(index);



      },

      makeMarkerIcon(status){

            let icon='';
            switch(status){
                  case 'available':icon='marker-available.png';break;
                  case 'deposit':icon='marker-hold.png';break;
                  case 'sold':icon='marker-sold.png';break;

                }

          return L.icon({
                  iconUrl: '/images/'+icon,
                  iconSize: [25, 35],
                  iconAnchor: [16, 37]
                })
      },


        getDevelopment(){

          api.get('/developments/'+this.$route.params.id+'?expand=phases,phases.stages').then(response=>{

            this.setData(response);
            
            this.loadPhase(0);
            
          }).catch(()=>{

          }).finally(()=>{


          });
          
        },

        setData(response){


          
          this.dev_image=this.$thumb(response.data.featured_image);
          this.dev_title=response.data.name;

          this.development=response.data;
          this.phases=response.data.phases;          
          this.currentPhase=this.phases[0].name;


            if(this.phases.length>0){
              this.phase=this.phases[0];
              
              
              if(this.phase.stages.length>0){
                
                this.stages=this.phases[0].stages;
                this.stage=this.phases[0].stages[0];
              }
              


            }

          },

        loadStage(index){
          
          const stage_id=this.stages[index].id;

          this.selectedStage=this.shortText(this.stages[index].name);
          
          api.get('/phases/'+stage_id+'?expand=properties,properties.design,properties.design.currency').then(response=>{
            this.properties=response.data.properties;
            this.configMap(response.data);
          });


        },


        loadPhase(index){
          this.selectedPhase=this.shortText(this.phases[index].name);
          this.stages=this.phases[index].stages;

          this.loadStage(0);

        },


        shortText(text){

            let t=text.substring(0,15);
            return t;


        },


        configMap(config){
          

          if(config.hasOwnProperty('map')){
            
            this.map.url=this.$pic(config.map);
          }
          
          if(config.hasOwnProperty('map_width') && config.hasOwnProperty('map_height')){

            

            const map_width=parseFloat(config.map_width);
            const map_height=parseFloat(config.map_height);
            

            this.map.bounds=latLngBounds([[0, 0], [map_height,map_width]]);
            this.map.maxBounds=latLngBounds([[-this.map_padding, -this.map_padding], [map_height+this.map_padding,map_width+this.map_padding]]);
            this.map.center=[map_width/2,map_height/2];

            
            
          }


            
            if(config.hasOwnProperty('properties')){


              this.map.markers=[];

                    for(let i in config.properties){

                      
                      const p=config.properties[i];
                      

                      this.map.markers.push({
                        id:p.id,
                        code:p.code,
                        icon:this.makeMarkerIcon(p.status),
                        position_x:p.position_x,
                        position_y:p.position_y,
                        active:p.active,
                        design_id:p.design_id,
                        design:p.design,
                        status:p.status,
                        draggable:false,
                        counter:0
                        
                      });
                

              }


            }




      this.key_map++;




    },


    onSearchInput(){


      if(this.map.markers.length==1){
        this.setMapCenter([this.map.markers[0].position_x,this.map.markers[0].position_y]);
      }else{
        this.fitMapBounds();
      }

    },

    fitMapBounds(){
      const bounds = new L.LatLngBounds(this.map.markers.map(marker => [marker.position_x, marker.position_y]),{padding:[100,100]});
      this.$refs.lmap.mapObject.fitBounds(bounds);

    },

    setMapCenter(center){

      this.map.center=center;
      
    },


    onMarkerClick(idx){


      this.prop=this.map.markers[idx];
      this.sale_price=this.prop.design.price;
      this.property_id=this.map.markers[idx].id;
      
      
      this.openQuotationDialog();  

    },

    openQuotationDialog(){

        this.$refs.quotation_dialog.show();


    },


    




    },
    

    mounted(){


      this.development_id=this.$route.params.id;

      this.getDevelopment();


    },

    watch:{
      


    }





}



</script>


<style lang="scss" scoped>

.srch-btn{padding:9px}
.srch-input{min-width: 250px;}

.dev-wrapper{min-height:470px}

.dev-details img{width:100%}

.min-pad{padding:0 10px}

.dev-thumb{width:44px;height: 44px;}



.prop-payment-selector{
    
    .payment-radio{
        display: inline-block;
        margin:10px 10px 0 0

    }


}



</style>