<template>

    
        <section class="development-detail">
    
    
    <div class="page-header">
        
        
          <nav aria-label="breadcrumb">
            <ul class="breadcrumb">
              <li class="breadcrumb-item active" aria-current="page">
                <a href="">Dashboard</a> / <a href="">Mis desarrollos</a> / {{ model.name }}
              </li>
            </ul>
          </nav>
        
    </div>
 
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">

            <div class="development-header">

              <div class="row">

                <div class="col-md-9 col-lg-9 col-sm-9">

                    


                    <div class="float-left">
                      <b-skeleton-img v-if="!isReady" no-aspect width="250px" height="200px"></b-skeleton-img>
                      <img v-if="isReady" class="thumb-dev-detail" v-bind:src="thumb(model.featured_image)">
                    </div>
                    <div class="development-prices">
                      <b-card v-if="!isReady">
                        <b-skeleton animation="wave" width="85%"></b-skeleton>
                        <b-skeleton animation="wave" width="55%"></b-skeleton>
                        <b-skeleton animation="wave" width="70%"></b-skeleton>
                      </b-card>
                       
                          <div v-if="isReady">
                              <h4>{{ model.name }}</h4>
                                <div class="range-prices">
                                  <h3>
                                    $ {{ $numberFormat(model.min_price) }} {{ model.min_currency }} - $ {{ $numberFormat(model.max_price) }} {{ model.max_currency }}
                                    
                                  </h3>
                                </div>
                                <span>Uso: {{ model.use_type }}</span>
                                <p>{{ model.address }}</p>

                                <label><ui-switch @input="onPrivateChange($event,index)" :outputType="'numeric'" v-model="model.is_private" v-b-tooltip.hover title="Activar/Desactivar"></ui-switch> Desarrollo privado</label>
                          </div>
                    
                    </div>


                </div>
                <div class="col-md-3 col-sm-3 col-lg-3">
                    <router-link v-if="isReady" :to="'/developments/update/'+model.id" class="btn btn-primary"><i class="mdi mdi-square-edit-outline"></i> Editar desarrollo</router-link>
                    <b-skeleton width="100%" v-if="!isReady" type="button"></b-skeleton>
                    <br>
                    <br>
                    <a v-if="isReady" class="btn btn-primary" href="javascript:void(0)" @click="deleteMe(model.id)"><i class="mdi mdi-delete"></i> Eliminar desarrollo</a>
                    <b-skeleton width="100%" v-if="!isReady" type="button"></b-skeleton>
                </div>


              </div>


            </div>

            <div class="development-description">

              {{ model.description }}
            </div>
            


            <b-tabs class="clear"  v-model="currentTab" ref="devTabs" content-class="mt-3">
                <b-tab>
                    <template slot="title">
                        <i class="mdi mdi-office-building-cog"></i>
                        General
                    </template>
                        <div>
                          <p><strong>Fecha de inicio</strong></p>
                          {{ this.$dateFormat(model.start_date) }}
                        </div>
                        <div>
                         <p><strong>Fecha de entrega</strong></p>
                          {{ this.$dateFormat(model.end_date) }}
                        </div>
                        <br/>
                        <div>
                          <strong>Amenidades</strong>
                        </div>

                        <div class="list-amenities">

                            <div v-for="(amenity,index) in model.amenities" class="item-amenity" :key="index">
                                {{  amenity.name}}
                            </div>

                        </div>

                        <br/>
                        <div>
                          <h5>Brochure</h5>
                          <a class="action-link" :title="filename(model.brochure)" v-if="model.brochure" target="_blank" :href="fil(model.brochure)"><i :class="fileico(model.brochure)+'  mdi-24px'"></i> {{filename(model.brochure)}}</a>
                        </div>

                </b-tab>

                <b-tab>
                  <template slot="title">
                      <i class="mdi mdi-math-compass"></i>
                      Modelos
                  </template>

                  <div v-for="(design,index) in model.designs" class="design-row" :key="index">

                    <div class="list-developments">
                      
                      <div class="row">
                              <div class="col-md-3 col-sm-3 col-lg-3">
                                <img :src="$thumb(design.image)"/>
                              </div>
                              <div class="col-md-3 col-sm-3 col-lg-3">
                                <h5>{{ design.name }}</h5>
                                <ul>

                                  <li>Recámaras: {{ design.rooms }}</li>
                                  <li>Baños: {{ design.bathrooms }}</li>
                                  <li>Construcción: {{ design.m2inside }} m2</li>
                                  <li>Terraza: {{ design.m2outside }} m2</li>
                                  
                                </ul>
                              </div>

                              <div class="col-md-3 col-sm-3 col-lg-3">
                                  <h5>Amenidades</h5>
                                  <div class="list-amenities">
                                    <ul>
                                      <li v-for="(eq,index) in equipmentrange(index,0,8)" :key="index">
                                          {{ eq.name }}
                                      </li>
                                    </ul>
                                  </div>

                              </div>
                              <div class="col-md-3 col-sm-3 col-lg-3">

                                  <div class="list-amenities">
                                    <ul>
                                      <li v-for="(eq,index) in equipmentrange(index,9,18)" :key="index">
                                          {{ eq.name }}
                                      </li>
                                    </ul>
                                  </div>

                              </div>
                        </div>                      
                    </div>
                  </div>

                </b-tab>
                <b-tab>
                  <template slot="title">
                    <i class="mdi mdi-home-percent-outline"></i>
                      Inventario
                  </template>
                  
                  <!-- <embedListProperties v-if="showListProperties" :key="countDesigns" :default_developments="makeDevelopments" :default_designs="model.designs" :data="model.properties" :default_development_id="parseInt(model.id)"/>--->
                  <tableGrid v-if="isReady" :routeName="'properties?expand=design&filter[development_id]='+model.id">
                    <template slot="headers">
                      <th>ID</th>
                      <th>Clave</th>
                      <th>Diseño</th>
                    <th>Acciones</th>
                </template>

                <template v-slot:row="item">
                  
                  
                  <td>{{ item.id }}</td>
                  <td>{{ item.code }}</td>
                  <td>{{ item.design.name }}</td>
                  
                  <!--
                    <td>
                      <div class="progress">
                        <div class="progress-bar bg-gradient-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </td>-->
                    
                    
                  </template>
                  <template v-slot:start="item">
                    <router-link  class="action-link" :to="link('/detail/'+item.id)"><i class="mdi mdi-chart-bar"></i></router-link>
                  </template>
                  
                  
                </tableGrid>
                
                
                
              </b-tab>
              <b-tab>
                <template slot="title">
                  <i class="mdi mdi-image-multiple"></i>
                  Galería
                </template>
                
                
                <div class="development-gallery">
                  
                  <div v-for="(gallery,index) in model.developmentgalleries" :key="index">
                    <img :src="$thumb(gallery.image)"/>
                  </div>
                  
                </div>
                
                
                
              </b-tab>              
              
              <b-tab>
                <template slot="title">
                  <i class="mdi mdi-file-document-multiple-outline"></i>
                  Documentos
                </template>
                
                
                  <tableGrid :data="model.documents" v-if="hasDocuments" :deletable="false" :updatable="false">

                    <template slot="headers">
                      <th>Nombre</th>
                      <th>Acciones</th>
                      
                    </template>
                    
                    <template v-slot:row="item">
                      
                      <td>{{ item.name }}</td>
                      
                    </template>
                    
                    <template v-slot:start="item">
                      
                      <a href="javascript:void(0)" class="action-link" @click="openDocument(item)"><i class="mdi mdi-text-box-search-outline"></i></a>
                      
                    </template>
                    
                    
                  </tableGrid>
                  
                  
                  
                  <b-modal ref="modalDocument" :hide-footer="true">
                    
                    <viewDocument :id="document_id"/>
                    
                    
                  </b-modal>
                  
                  
                  
                </b-tab>

                <b-tab>
                  <template slot="title">
                    <i class="mdi mdi-calculator-variant-outline"></i>
                      Cotizador
                  </template>

                  <quoteMap v-if="mapOpened" @savedQuote="updateQuotations" :development_id="$route.params.id"></quoteMap>

                </b-tab>

                
                <b-tab>
                  <template slot="title">
                    <i class="mdi mdi-file-percent-outline"></i>
                      Cotizaciones
                  </template>

                  <tableGrid v-if="isReady" :data="model.quotations" :routeName="'quotations'" :key="quotation_counter" :updatable="false">
                    <template slot="headers">

                        <th>Cliente</th>
                        <th>Clave de unidad</th>
                        <th>Precio de venta</th>
                        <th>Fase</th>
                        <th>Etapa/Piso</th>
                        <th>Acciones</th>
                      
                    </template>

                    <template v-slot:row="item">
                        <td>{{ item.client.name }}</td>
                        <td>{{ item.property.code }}</td>
                        <td>{{ item.sale_price }}</td>
                        <td>{{ item.property.stage.parent.name }}</td>
                        <td>{{ item.property.stage.name }}</td>
                    </template>


                    <template v-slot:start="{item,index}">

                      <a class="action-link" @click="viewQuotation(index)"><i class="mdi mdi-eye"></i></a>

                    </template>

                  </tableGrid>
                  

                  <b-modal ref="quotation_view" size="lg" title="Ver cotización" :hide-footer="true">
                    <viewQuotation :id="quotation_id" />
                  </b-modal>



                </b-tab>


                <b-tab>
                  <template slot="title">
                    <i class="mdi mdi-folder-home-outline"></i>
                      Expendientes
                  </template>



                </b-tab>


            </b-tabs>



                
          </div>
        </div>
      </div>
    </div>



    </section>

</template>


<script>
import api from "@/util/api.js";
import uiSwitch from "@/components/ui-feature/switch";
//import embedListProperties from '@/pages/properties/embedList';
import tableGrid from '@/components/tableGrid';
import viewDocument from '@/pages/documents/view';
import viewQuotation from '@/pages/quotations/view';
import quoteMap from '@/pages/quotations/map';

export default{
    routeName:'developments',
    name:"viewDevelopment",
    components:{
      uiSwitch,
     // embedListProperties,
      tableGrid,
      viewDocument,
      viewQuotation,
      quoteMap
    },
    
    data(){

        return {

          currentTab:0,
          document_id:'',
          quotation_id:0,
          quotations_counter:0,
            model:{

                id:0,
                name:'',
                featured_image:'',
                min_price:0,
                max_price:0,
                min_currency:'',
                max_currency:'',
                use_type:'',
                address:'',
                is_private:false,
                start_date:'',
                end_date:'',
                amenities:[],
                description:'',
                developmentgalleries:[],
                properties:[],
                designs:[],
                brochure:'',
                documents:[],
                quotations:[]

            }

        }



    },


    computed:{


      mapOpened(){

        return this.currentTab==5?true:false;

      },


      hasDocuments(){

          return this.model.documents.length>0?true:false;
      },  
      isReady(){

        return this.model.id!=0?true:false;
      },
      equipmentrange(){

        return function(index,start,end){

          return this.model.designs[index].equipment.slice(start,end+1);


        }

      }

    },

    methods:{

      deleteMe(id){


        if(confirm('¿Está seguro de eliminar este desarrollo?')){

          api.delete(this.me(id)).then(()=>{
            
            this.$notify({message:'Elemento eliminado',type:'success'});
            this.$router.push('/developments');
            
          }).catch(()=>{
            
            this.$notify({message:'No es posible eliminar este desarrollo','type':'error'});
            
          });
        }
          

      },

      updateQuotations(response){

        
        let item=response.data.quotation;

        item.client=response.data.client;
        item.property=response.data.property;
        item.property.stage=response.data.property.stage;
        item.property.stage.parent=response.data.property.stage.parent;

        this.model.quotations.push(item);
        this.quotation_counter++;
      },

      openDocument(item){

        this.document_id=item.id;
        this.$refs.modalDocument.show();


      },

      onPrivateChange(){

        api.get(this.me('/setprivate?flag='+this.model.is_private));
        
      },


      showListProperties(){              
        return this.model.id && this.model.designs.length>0;
      },

      viewQuotation(index){

        
        this.quotation_id=this.model.quotations[index].id;
        this.$refs.quotation_view.show();
      },

    },

    mounted(){



        api.get(this.me(this.$route.params.id+'?expand=documents,amenities,developmentgalleries,designs,properties,designs.equipment,quotations,quotations.client,quotations.property,quotations.property.stage,quotations.property.stage.parent,steps')).then(response=>{


            this.fillModel(this.model,response.data);
          console.log(this.model);

        }).catch(()=>{


        }).finally(()=>{



        });



    }




}
</script>

<style lang="scss" scoped>


.development-gallery{display:flex}
.development-gallery div{width:calc(20% - 40px);margin:20px}

.design-row{margin-bottom:30px}

</style>